import colorPalette from './colorPalette';

export const DarkColors = {
  Background: '#171923',
  Container: {
    Bg: '#212330',
    Stroke: '#27283A',
  },
  Card: {
    Bg: '#212330',
    Stroke: '#434557',
  },
  Divider: '#434557',
  LinkContainer: {
    BgDefault: '#262937',
    BgHover: '#303343',
    BgPressed: '#303343',
    StrokeDefault: '#434764',
    StrokeHover: '#3F4355',
  },
  Buttons: {
    Primary: {
      BgDefault: '#5D66F1',
      BgHover: '#505AF4',
      BgPressed: '#5D64D1',
      BgDisabled: '#2A2C46',
      LabelDefault: colorPalette.WHITE,
      LabelDisabled: '#484B7A',
    },
    Secondary: {
      BgDefault: '#2C2F41',
      BgHover: '#33364B',
      BgPressed: '#252737',
      BgDisabled: '#2A2C46',
      BgPressedStroke: '#252737',
      SecondaryLabel: '#5D66F1',
      LabelDisabled: '#484B7A',
    },
    Tertiary: {
      BgDefault: '#212330',
      BgHover: 'rgb(27, 29, 40, 0.62)',
      BgPressed: 'rgb(27, 29, 40, 0.62)',
      LabelDefault: '#747CFF',
      LabelDisabled: '#343659',
      LabelHover: '#8D94FF',
      LabelPressed: '#5F64B1',
    },
    Label: {
      BgDefault: colorPalette.TRANSPARENT,
      BgHover: colorPalette.TRANSPARENT,
      BgPressed: 'rgb(27, 29, 40, 0.62)',
      LabelDefault: '#747CFF',
      LabelDisabled: '#343659',
      LabelHover: '#8D94FF',
      LabelPressed: '#5F64B1',
    },
  },
  Text: {
    Headline: colorPalette.WHITE,
    Paragraph: '#BEC0C9',
    SubtleText: '#7D83A8',
    SubHeadline: '#AFB3CA',
    Caption: '#A1A4B5',
    Disabled: '#484B7A',
  },
  LinkText: {
    Default: colorPalette.WHITE,
    Active: '#747CFF',
  },
  SkillChip: {
    BgDefault: '#2A2D3D',
    StrokeDefault: '#434557',
    TextDefault: '#9194A9',
    TextActive: colorPalette.WHITE,
    BgPressed: '#303343',
    TextPressed: '#99A6C0',
  },
  StaticDataChip: {
    BgDefault: '#2A2D3D',
    StrokeDefault: '#434557',
    TextDefault: '#9194A9',
    TextActive: colorPalette.WHITE,
    BgPressed: '#303343',
    TextPressed: '#99A6C0',
  },
  Icons: {
    Primary: {
      BgDefault: '#5D66F1',
      BgHover: '#505AF4',
      BgPressed: '#5D64D1',
      IconFill: colorPalette.WHITE,
    },
    Secondary: {
      BgDefault: 'rgb(27, 29, 40, 0.62)',
      BgHover: 'rgb(27, 29, 40, 0.62)',
      BgPressed: 'rgb(27, 29, 40, 0.62)',
      IconFill: '#5D66F1',
    },
    Tertiary: {
      BgDefault: '',
      IconFillDefault: colorPalette.WHITE,
      IconFillHover: '#5D66F1',
      IconFillPressed: '#5D66F1',
      BgHover: 'rgb(27, 29, 40, 0.62)',
      BgPressed: 'rgb(27, 29, 40, 0.62)',
    },
  },
  Badge: {
    BgDefault: '#303343',
    CountDefault: colorPalette.WHITE,
    BgHover: '#505AF4',
    CountHover: colorPalette.WHITE,
  },
  Stroke: {
    Default: '#434764',
    Active: colorPalette.WHITE,
  },
  Tabs: {
    Primary: {
      BgDefault: '#212330',
      BgActive: '#212330',
      StrokeDefault: '#434764',
      StrokeHover: '#747CFF',
      StrokeActive: '#747CFF',
      LabelAndIconDefault: '#7D83A8',
      LabelAndIconHover: '#747CFF',
      LabelAndIconClicked: '#747CFF',
    },
    Secondary: {
      ContainerBg: '#212330',
      ContainerStroke: '#434764',
      BgDefault: '#212330',
      BgHover: 'rgb(27, 29, 40, 0.62)',
      BgClicked: 'rgb(27, 29, 40, 0.62)',
      LabelDefault: '#7D83A8',
      LabelClicked: '#747CFF',
      LabelHover: '#747CFF',
    },
  },
  Success: '#4AB543',
  Failure: '#EE3838',
  CoversBackground: {
    ProfileCoverBg: '#181923',
    StaticDataPageCoverBg: '#181923',
    KeyContactProfileCoverBg: '#181923',
  },
  CommentBg: '#2A2D3D',
  ToolTip: {
    Bg: '#CCD7E6',
    Text: '#3C4352',
  },
  OrgChart: {
    ContainerBg: '#212330',
    ContainerStrokeDefault: '#282B3C',
    ContainerStrokeSelected: '#5D66F1',
    Divider: '#434557',
    PrimaryCountBg: '#5D66F1',
    SecondaryCountBg: '#7D83A8',
    CountFill: colorPalette.WHITE,
    Headline: colorPalette.WHITE,
    SubHeadline: '#7D83A8',
    BackgroundOne: '#1A1C27',
    BackgroundTwo: '#171923',
  },
  StatusDropdown: {
    Open: {
      BgDefault: 'rgb(31, 89, 36, 0.52)',
      BgHover: 'rgb(46, 133, 52, 0.52)',
      BgClicked: 'rgb(31, 89, 36, 0.52)',
      LabelAndIconDefault: '#66BB6A',
    },
    Closed: {
      BgDefault: 'rgb(49, 54, 82, 0.47)',
      BgHover: 'rgb(59, 65, 101, 0.47)',
      BgClicked: 'rgb(46, 51, 85, 0.47)',
      LabelAndIconDefault: '#94A0B8',
    },
  },
  SelectionChips: {
    Primary: {
      BgDefault: '#5D66F1',
      BgHover: '#505AF4',
      BgPressed: '#5D64D1',
      LabelAndIconDefault: colorPalette.WHITE,
    },
    Secondary: {
      BgDefault: '#2A2D3D',
      StrokeDefault: '#434557',
      StrokeHover: colorPalette.WHITE,
      StrokeClicked: colorPalette.WHITE,
      LabelAndIconDefault: '#7D83A8',
      LabelAndIconHover: colorPalette.WHITE,
    },
  },
  MarkdownEditor: {
    Background: '#2A2D3D',
    IconDefault: '#7D83A8',
    IconHover: '#747CFF',
    IconClicked: '#747CFF',
  },
  TextFields: {
    Default: '#474E71',
    Hover: '#6B75A5',
    Clicked: colorPalette.WHITE,
    Disabled: '#2A2C46',
    LabelDefault: '#818CC1',
    LabelClicked: colorPalette.WHITE,
    HintText: '#7D83A8',
    InputText: colorPalette.WHITE,
  },
  SpecialButtons: {
    Delete: {
      BgDefault: 'rgb(255, 255, 255,0)',
      BgHover: '#292D40',
      BgPressed: '#191C2F',
      LabelDefault: '#D2D2D2',
      LabelHover: '#EC2B2B',
      LabelPressed: '#EC2B2B',
    },
    Like: {
      BgDefault: '#212330',
      BgHover: '#1B1D28',
      BgPressed: '#212330',
      LabelDefault: '#7D83A8',
      LabelHover: '#7D83A8',
      LabelActive: '#EAAF00',
    },
    Comment: {
      BgDefault: '#212330',
      BgHover: '#1B1D28',
      BgPressed: '#212330',
      LabelDefault: '#7D83A8',
      LabelHover: '#7D83A8',
      LabelActive: '#7D83A8',
    },
  },
  EmptyPlaceholderIcons: {
    Background: '#282B3A',
    IconFill: '#505470',
  },
  EventTagChip: {
    Background: '#2b2e45',
    Text: '#DFE9FE',
    SecondaryBackground: '#1C3059',
    Collaboration: {
      IconFill: '#4CB9E7',
      Background: '#004B6C',
    },
    'Company Updates': {
      IconFill: '#65B741',
      Background: '#174501',
    },
    Culture: {
      IconFill: '#FB8B24',
      Background: '#74631C',
    },
    Customer: {
      IconFill: '#29ADB2',
      Background: '#005D5F',
    },
    Learning: {
      IconFill: '#FFC436',
      Background: '#644A0C',
    },
    'Public Holiday': {
      IconFill: '#B70404',
      Background: '#530F0F',
    },
    Recruitment: {
      IconFill: '#793FDF',
      Background: '#3C2E55',
    },
  },
  DropdownList: {
    Bg: '#212330',
    Hover: '#282B3C',
    Label: '#7D83A8',
  },
  Extras: {
    Extra1: colorPalette.WHITE,
    Extra2: '#504614',
    Extra3: '#FFD000',
    Extra4: '#3B1A3E',
    Extra5: '#D554E7',
    Extra6: '#0A3334',
    Extra7: '#54C4C6',
    Extra8: '#2D1507',
    Extra9: '#E95600',
    Extra10: '#20223C',
    Extra11: '#7C84FF',
    Extra12: 'rgb(0, 0, 0, 0.45)',
    Extra13: '#28293E',
  },
};
