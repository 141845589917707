import React from 'react';
import MUIAccordion, { AccordionProps } from '@mui/material/Accordion';
import MUIAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MUIAccordionDetails, { AccordionDetailsProps } from '@mui/material/AccordionDetails';
import styled from '@mui/material/styles/styled';

const Accordion: React.FC<AccordionProps> = (props) => {
  const { children, ...accordionProps } = props;

  return <MUIAccordion {...accordionProps}>{children}</MUIAccordion>;
};

export const AccordionSummary: React.FC<AccordionSummaryProps> = (props) => {
  const { children, ...accordionSummaryProps } = props;

  return <MUIAccordionSummary {...accordionSummaryProps}>{children}</MUIAccordionSummary>;
};

export const StyledAccordionSummary = styled(AccordionSummary)({
  flexDirection: 'row-reverse',
  minHeight: 'auto',
  '&.Mui-expanded': {
    minHeight: 'auto',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
});

export const AccordionDetails: React.FC<AccordionDetailsProps> = (props) => {
  const { children, ...accordionDetailsProps } = props;

  return <MUIAccordionDetails {...accordionDetailsProps}>{children}</MUIAccordionDetails>;
};

export default Accordion;
