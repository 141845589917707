import React from 'react';
import { useTheme } from '@mui/material';

import { UserAvatar } from 'features/profiles/components/user-avatar';
import Stack from 'common/components/material/Stack';
import { Person } from 'features/profiles';
import { EllipsisText } from '../ellipsis-text/ellipsis-text.component';
import AppRoutesEnum from 'common/routes/AppRoutes.enum';
import { useRouter } from 'common/hooks';
import { HoverArrowIcon } from 'common/components/navigation/HoverArrowIcon';
import { LinkTileContainer } from '../link-tile-with-avatar/link-tile-container.component';
import { FeaturesEnum } from 'features/feature-access/features.enum';
import { useFeatureAccess } from 'features/feature-access/hooks/useFeatureAccess';
import useInstancePermissions from 'features/feature-access/hooks/useInstancePermissions';
import { InstancePermissionsResourcesEnum } from 'features/feature-access/enums/InstancePermissions.enum';

export const PersonLinkContainer: React.FC<{ person: Person }> = ({ person }) => {
  const { palette } = useTheme();
  const { getOrgRoute } = useRouter();

  const { canRead: canNavigateToProfile } = useFeatureAccess(FeaturesEnum.OTHER_PROFILE);
  const { permissions } = useInstancePermissions(
    InstancePermissionsResourcesEnum.PROFILE,
    person,
  );

  const route = getOrgRoute(AppRoutesEnum.PROFILE, {
    profileId: person.id,
  });

  const canInteract = canNavigateToProfile && permissions.interact;

  return (
    <LinkTileContainer
      sx={{ p: 0.5, backgroundColor: 'inherit' }}
      route={route}
      showBorder={false}
      readonly={!canInteract}
    >
      <Stack spacing={1} direction="row" alignItems="center">
        <UserAvatar fullName={person?.fullName} src={person?.photo} size="medium" />
        <Stack minHeight="1.5rem" justifyContent="center">
          <EllipsisText
            className="link-title"
            variant="body4"
            fontWeight="500"
            color={palette.LinkText.Default}
            lineclamp={1}
          >
            {person.fullName}
          </EllipsisText>
          <EllipsisText
            className="link-sub-title"
            lineclamp={1}
            variant="caption"
            color={palette.Extras.Extra1}
          >
            {person.profileHeadline}
          </EllipsisText>
        </Stack>
      </Stack>
      {canInteract && <HoverArrowIcon className="show-on-hover" />}
    </LinkTileContainer>
  );
};
