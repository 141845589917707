import moment from 'moment';
import axios from 'common/utils/axios';
import React, { Fragment } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Typography, Grid, Skeleton, Divider, Stack, Box } from 'common/components/material';
import useSession from 'common/hooks/useSession';
import useParamsProfileId from 'common/hooks/useParamsProfileId';
import useQuery from 'common/hooks/useQuery';
import Accordion, {
  AccordionDetails,
  StyledAccordionSummary,
} from 'common/components/material/Accordion';
import useTheme from '@mui/material/styles/useTheme';
import { CXViewer } from 'libs/toast-ui/components/cx-viewer.component';
import { EllipsisText } from 'common/components/ellipsis-text/ellipsis-text.component';
import HorizontalScrollableContainer from 'common/components/container/HorizontalScrollableContainer';
import TagChip from 'features/tags/components/tag-chip.component';
import useView from 'common/hooks/useView';
import { Tag } from 'features/tags/types/tag.type';
import useIntersectionObserver from 'features/org-root/hooks/useIntersectionObserver';
import EndorsersAvatarGroup from 'features/org-root/components/EndorsersAvatarGroup';

const matchingEngineApi = axios('matching-engine');

const useTrackRecord = (
  profileId: string,
  skillId?: string,
  stepId?: string,
  cutOffDays?: number,
  disabled?: boolean,
) => {
  const { account: user, hasOrganization } = useSession();

  const getRecords = () => {
    const params = {
      profileId,
      skillId,
      stepId,
      cutOffDays,
    };
    return matchingEngineApi.get(`track-record`, { params });
  };

  const queryKey = [
    'matching-engine',
    user?.organizationId,
    profileId,
    'track-record',
    skillId ?? stepId,
  ];

  const queryResponse = useQuery(queryKey, getRecords, {
    enabled: !!(hasOrganization && profileId && (skillId || stepId)) && !disabled,
  });

  return {
    ...queryResponse,
    data: queryResponse.data?.data,
  };
};

type WorkItem = {
  id: string;
  date: Date;
  totalContributedDays: number;
  summary: string;
  description?: string;
  impact?: string;
  sponsor?: string;
  feedback?: string[];
  tags: Tag[];
};

export const RecentlyWorkedItem: React.FC<WorkItem> = (workItem) => {
  const { isDesktopView } = useView();
  const { palette } = useTheme();

  const labels = [dateLabel(), effortLabel(), impactLabel(), sponsorLabel()];
  return (
    <Stack spacing={2} py={1}>
      <Box>
        <Stack flexDirection="row" justifyContent="space-between" lineHeight="2.25rem">
          <EllipsisText
            variant="button1"
            fontWeight={600}
            color={palette.Text.Headline}
            lineclamp={1}
          >
            {workItem.id}: {workItem.summary}
          </EllipsisText>
        </Stack>
        <Box lineHeight={1}>
          <Typography variant="caption" color={palette.Text.SubHeadline}>
            {labels.join(' — ')}
          </Typography>
        </Box>
      </Box>
      {workItem.description && (
        <Stack>
          <Typography variant="body4" color={palette.Text.SubHeadline}>
            Details
          </Typography>
          <Typography variant="body3" component="div" color={palette.Text.Headline}>
            <CXViewer value={workItem.description} />
          </Typography>
        </Stack>
      )}
      {workItem.feedback && workItem.feedback.length > 0 && (
        <Stack>
          <Typography variant="body4" color={palette.Text.SubHeadline}>
            Customer feedback
          </Typography>
          <Typography variant="body3" component="div" color={palette.Text.Headline}>
            <CXViewer value={workItem.feedback.map((s: string) => `> ${s}`).join('\n\n')} />
          </Typography>
        </Stack>
      )}
      <Stack spacing={1.5}>
        <Stack direction="row" spacing={3.5} alignItems="center">
          <Grid width={isDesktopView ? '90%' : '75%'}>
            {workItem.tags.map(({ ...tag }) => (
              <TagChip key={tag.id} tag={tag} sx={{ mr: 1, mb: 1 }} />
            ))}
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  );

  function dateLabel() {
    return moment.utc(workItem.date).format('MMM YYYY');
  }

  function effortLabel() {
    if (workItem.totalContributedDays < 7) {
      return `Effort: about 1 week`;
    } else if (workItem.totalContributedDays < 30) {
      return `Effort: about 1 month`;
    } else if (workItem.totalContributedDays < 90) {
      return `Effort: about 3 months`;
    } else if (workItem.totalContributedDays < 180) {
      return `Effort: about half a year`;
    } else if (workItem.totalContributedDays < 365) {
      return `Effort: about a year`;
    } else {
      return `Effort: more than a year`;
    }
  }

  function impactLabel() {
    return `Impact: ${workItem.impact ?? '<NONE>'}`;
  }

  function sponsorLabel() {
    return `Sponsor: ${workItem.sponsor ?? '<NONE>'}`;
  }
};

const RecentlyReceivedFeedback: React.FC<{
  profileId: string;
  skillId: string;
  date: Date;
  totalEndorsers: number;
  endorsers: any;
}> = ({ profileId, skillId, ...feedback }) => {
  const { isDesktopView } = useView();
  const { palette } = useTheme();

  const dateLabel = moment.utc(feedback.date).format('MMM YYYY');
  return (
    <Stack spacing={2} py={1}>
      <Box>
        <Stack flexDirection="row" lineHeight="2.25rem">
          <Typography fontWeight={600}>Endorsed by: </Typography>
          <Box sx={{ pl: 1 }}>
            <EndorsersAvatarGroup
              totalEndorsers={feedback.totalEndorsers}
              endorsers={feedback.endorsers}
              endorseeId={profileId}
              skillId={skillId}
            />
          </Box>
        </Stack>
        <Box lineHeight={1}>
          <Typography variant="caption" color={palette.Text.SubHeadline}>
            {dateLabel}
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};

const EndorsedSkillTrackRecord: React.FC<{
  profileId?: string;
  skillId?: string;
  stepId?: string;
  cutOffDays?: number;
}> = ({ profileId, skillId, stepId, cutOffDays }) => {
  const { palette } = useTheme();
  const { profileId: profileId2 } = useParamsProfileId();
  const { setNode, intersectionObserverEntry } = useIntersectionObserver();
  profileId = profileId ?? profileId2;
  const { data, isLoading } = useTrackRecord(
    profileId,
    skillId,
    stepId,
    cutOffDays,
    !intersectionObserverEntry?.isIntersecting,
  );

  if (isLoading || !data) {
    return (
      <Skeleton
        ref={setNode}
        variant="rectangular"
        sx={{ borderRadius: '0.75rem', mr: 2, mb: 3 }}
        height="6rem"
      />
    );
  }
  return (
    <Typography variant="body3" color={palette.Text.Headline}>
      {(data?.items ?? []).map((item: any, index: number) => (
        <Fragment key={item.id}>
          {index > 0 ? <Divider sx={{ my: '0.5rem' }} className="divider" /> : null}
          {item.type === 'WORK_ITEM' ? (
            <RecentlyWorkedItem {...item} />
          ) : item.type === 'FEEDBACK' ? (
            <RecentlyReceivedFeedback profileId={profileId} skillId={skillId} {...item} />
          ) : null}
        </Fragment>
      ))}
    </Typography>
  );
};

export default EndorsedSkillTrackRecord;
