import React from 'react';

import MUISkeleton, { SkeletonProps } from '@mui/material/Skeleton';

const Skeleton: React.FC<SkeletonProps> = React.forwardRef((props, ref) => {
  const { children, ...skeletonProps } = props;

  return (
    <MUISkeleton ref={ref} {...skeletonProps}>
      {children}
    </MUISkeleton>
  );
});

export default Skeleton;
