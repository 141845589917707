import { ExploreTabEnum } from 'common/enum/ExploreTab.enum';
import useSession from 'common/hooks/useSession';
import InfiniteScrollContainer from 'features/org-root/components/InfiniteScrollContainer';
import {
  createCursorQueryParams,
  getNextCursorParam,
} from 'features/org-root/utils/infiniteScrollApi.utils';
import SearchSkeletonView from 'features/search/components/search-skeleton-view.component';
import { useSearchParams } from 'react-router-dom';
import axios from 'common/utils/axios';
import useTheme from '@mui/material/styles/useTheme';
import Stack from 'common/components/material/Stack';
import Typography from 'common/components/material/Typography';
import useInfiniteQuery from 'common/hooks/useInfiniteQuery';
import JoinLeftIcon from '@mui/icons-material/JoinLeft';
import JoinLeftOutlinedIcon from '@mui/icons-material/JoinLeftOutlined';
import Card from 'common/components/material/Card';
import List, { ListSubheader } from 'common/components/material/List';
import Grid from 'common/components/material/Grid';
import { StyledSearchOption } from 'common/components/navigation/styles';
import { ListItemText } from 'common/components/material/ListItemButton';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { EllipsisText } from 'common/components/ellipsis-text/ellipsis-text.component';
import IconButton from 'common/components/material/IconButton';
import { SIZE_STYLE } from 'common/components/cx-avatar/cx-avatar.constants';
import { titleCase } from 'common/utils/app.utils';
import EndorsedSkillTrackRecord, {
  RecentlyWorkedItem,
} from 'features/profiles/components/EdnorsedSkillTrackRecord';
import Divider from 'common/components/material/Divider';
import Accordion, { StyledAccordionSummary } from 'common/components/material/Accordion';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AccordionDetails from '@mui/material/AccordionDetails/AccordionDetails';
import { PersonLinkContainer } from 'common/components/person-link/person-link-container.component';
import { Fragment, useState } from 'react';
import Box from 'common/components/material/Box';
import useQuery from 'common/hooks/useQuery';
import Skeleton from 'common/components/material/Skeleton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from 'common/components/material/Tooltip';
import { ButtonTabs } from 'common/components/navigation';
import useIntersectionObserver from 'features/org-root/hooks/useIntersectionObserver';

const matchingEngineApi = axios('matching-engine');

type MatchingEngineSuggestionsListProps = {
  onViewAllClick?: () => void;
};

export const MatchingEngineSuggestionList: React.FC<MatchingEngineSuggestionsListProps> = ({
  onViewAllClick,
  children,
}) => {
  const { palette } = useTheme();

  return (
    <List
      subheader={
        <ListSubheader sx={{ lineHeight: '2.5rem', backgroundColor: palette.Container.Bg }}>
          <Grid display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body3" fontWeight={700} color={palette.DropdownList.Label}>
              Algos
            </Typography>
            <Typography
              onMouseDown={onViewAllClick}
              color={palette.Buttons.Tertiary.LabelDefault}
              variant="body3"
              fontWeight={500}
              display="flex"
              sx={{ cursor: 'pointer' }}
            >
              View All
            </Typography>
          </Grid>
        </ListSubheader>
      }
    >
      {children}
    </List>
  );
};

export const MatchingEngineSearchOptionItem: React.FC<{
  algo: any;
  searchTerm?: string;
}> = ({ algo, searchTerm = '' }) => {
  const { palette } = useTheme();
  const { fullName, description } = extractText(algo);
  const matches = match(description, searchTerm, { insideWords: true });
  const parts = parse(description, matches);

  return (
    <Stack
      direction="row"
      display="flex"
      alignItems="center"
      spacing={1}
      data-analytics-tag="search_suggest_choose_matching_engine"
    >
      <IconButton
        sx={{
          padding: 0,
          backgroundColor: palette.Icons.Secondary.BgDefault,
          color: palette.Icons.Secondary.IconFill,
          ...SIZE_STYLE.small.avatar,
          '&:hover': {
            backgroundColor: palette.Icons.Secondary.BgHover,
          },
        }}
      >
        <JoinLeftIcon sx={{ fontSize: '1.125rem ' }} />
      </IconButton>
      <Typography
        variant="body4"
        fontWeight={500}
        color={palette.LinkText.Default}
        whiteSpace="pre"
        maxWidth={description ? '75%' : '100%'}
        flexShrink={0}
      >
        {fullName}
      </Typography>
      {description && (
        <EllipsisText variant="body4" lineclamp={1}>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{
                color: part.highlight ? palette.Text.Headline : palette.Text.SubHeadline,
                fontWeight: part.highlight ? 500 : 400,
              }}
            >
              {part.text}
            </span>
          ))}
        </EllipsisText>
      )}
    </Stack>
  );

  function extractText(item: any) {
    switch (item.type) {
      case 'ALGO':
        return { fullName: item.id, description: titleCase(item.type) };
      case 'STEP': {
        const hasParent = item.parentId ?? item.algoId;
        let description = titleCase(item.type);

        if (hasParent) {
          description += ` in ${hasParent}`;
        }
        return { fullName: item.id, description };
      }
      case 'SKILL': {
        const [fullName, ...rest] = item.id.split(/ [-–] /);
        const description = rest.length ? rest.join(' - ') : titleCase(item.type);
        return { fullName, description };
      }
      default:
        throw new Error(`Cannot be here!`);
    }
  }
};

export const MatchingEngineSearchOption: React.FC<{
  algo: any;
  searchTerm?: string;
  active?: boolean;
  onClick?: (algo: any) => void;
}> = ({ algo, searchTerm, active, onClick }) => {
  const { palette } = useTheme();

  return (
    <StyledSearchOption
      key={algo.id}
      onMouseDown={() => onClick && onClick(algo)}
      dense={true}
      sx={{
        paddingY: '1px',
        backgroundColor: active ? palette.DropdownList.Hover : undefined,
      }}
    >
      <ListItemText>
        <MatchingEngineSearchOptionItem algo={algo} searchTerm={searchTerm} />
      </ListItemText>
    </StyledSearchOption>
  );
};

const useMatchingEngineResults = () => {
  const { account: user, hasOrganization } = useSession();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query') ?? '';

  const getRecords = (params: any) => {
    const queryParams = createCursorQueryParams(params.pageParam, {
      term: query,
    });
    return matchingEngineApi.get('search', { params: queryParams });
  };

  const queryKey = ['matching-engine', user?.organizationId, query];

  const queryResponse = useInfiniteQuery(queryKey, getRecords, {
    getNextPageParam: getNextCursorParam,
    entityKey: 'matches',
    enabled: hasOrganization,
  });

  const data = queryResponse.isSuccess ? queryResponse.data.pages : [];

  return {
    ...queryResponse,
    data,
  };
};

const NoResultsImage: React.FC = () => {
  const { palette } = useTheme();
  return (
    <Stack
      bgcolor={palette.EmptyPlaceholderIcons.Background}
      width={56}
      height={56}
      borderRadius="50%"
      alignItems="center"
      justifyContent="center"
    >
      <JoinLeftOutlinedIcon
        sx={{ color: palette.EmptyPlaceholderIcons.IconFill, fontSize: 24 }}
      />
    </Stack>
  );
};

const NoResults: React.FC = () => {
  const { palette } = useTheme();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={2.5}
      pt={4.5}
      data-analytics-tag="search_no_results_found"
    >
      <Stack spacing={1.5} alignItems="center" justifyContent="center">
        <NoResultsImage />
        <Typography variant="body2" fontWeight={500} color={palette.Text.Headline}>
          Nothing matched!
        </Typography>
        <Typography variant="body3" color={palette.Text.SubHeadline} textAlign="center">
          Try refine your search.
        </Typography>
      </Stack>
    </Stack>
  );
};

const PreCard: React.FC = (obj) => {
  return (
    <Card noElevation>
      <pre>{JSON.stringify(obj, null, 2)}</pre>
    </Card>
  );
};

const useCurrentAllocations = (profileId: string, disabled?: boolean) => {
  const { account: user, hasOrganization } = useSession();

  const getRecords = () => {
    const params = {
      profileId,
    };
    return matchingEngineApi.get(`current-allocations`, { params });
  };

  const queryKey = [
    'matching-engine',
    user?.organizationId,
    profileId,
    'current-allocations',
  ];

  const queryResponse = useQuery(queryKey, getRecords, {
    enabled: !!(hasOrganization && profileId) && !disabled,
  });

  return {
    ...queryResponse,
    data: queryResponse.data?.data,
  };
};

const CurrentAllocations: React.FC<{
  profileId: string;
}> = ({ profileId }) => {
  const { palette } = useTheme();
  const { setNode, intersectionObserverEntry } = useIntersectionObserver();
  const { data, isLoading } = useCurrentAllocations(
    profileId,
    !intersectionObserverEntry?.isIntersecting,
  );

  if (isLoading || !data) {
    return (
      <Skeleton
        ref={setNode}
        variant="rectangular"
        sx={{ borderRadius: '0.75rem', mr: 2, mb: 3 }}
        height="6rem"
      />
    );
  }

  if (data.items?.length === 0) {
    return (
      <Typography variant="body4" color={palette.Text.Headline} sx={{ fontStyle: 'italic' }}>
        Allocation details not available.
      </Typography>
    );
  }
  return (
    <Typography variant="body3" color={palette.Text.Headline}>
      {(data?.items ?? []).map((item: any, index: number) => (
        <Fragment key={item.id}>
          {index > 0 ? <Divider sx={{ my: '0.5rem' }} className="divider" /> : null}
          {item.type === 'WORK_ITEM' ? <RecentlyWorkedItem {...item} /> : null}
        </Fragment>
      ))}
    </Typography>
  );
};

const PERSON_DETAILS_TABS = [
  { value: 'Track record', label: 'Track record' },
  { value: 'Current allocations', label: 'Current allocations' },
];

const PersonDetails: React.FC<{ profileId: string; skillId?: string; stepId?: string }> = ({
  profileId,
  ...trackRecordProps
}) => {
  const [selectedTab, setSelectedTab] = useState(PERSON_DETAILS_TABS[0].value);
  return (
    <>
      <ButtonTabs
        selectedValues={[selectedTab]}
        tabs={PERSON_DETAILS_TABS}
        onChange={setSelectedTab}
        sx={{ mt: 1, mb: 1, gap: 0.75 }}
      />
      {selectedTab === 'Track record' ? (
        <EndorsedSkillTrackRecord profileId={profileId} {...trackRecordProps} />
      ) : (
        <CurrentAllocations profileId={profileId} />
      )}
    </>
  );
};

const MultiSkillGroupResult: React.FC<{
  nmatches?: number;
  id: string;
  name: string;
  people: any;
  skills: any;
}> = ({ nmatches, id, name, people, skills }) => {
  const { palette } = useTheme();
  return (
    <Card noElevation>
      <Accordion
        sx={{
          boxShadow: 'unset',
          width: 1,
          background: palette.Extras.Extra13,
          // Remove the top border: https://stackoverflow.com/a/76685872
          '&:before': { height: '0px' },
        }}
        // The content of Accordions is mounted by default even if the accordion is not expanded.
        // By enabling the unmountOnExit, we prevent the default behaviour.
        TransitionProps={{ unmountOnExit: true }}
        defaultExpanded={true}
      >
        <StyledAccordionSummary expandIcon={<NavigateNextIcon />} sx={{ py: 2 }}>
          <EllipsisText
            sx={{ fontSize: '1.5rem', fontWeight: 600 }}
            color={palette.Text.Headline}
            lineclamp={1}
          >
            People with matching skills
            <Tooltip
              title={
                <div style={{ whiteSpace: 'pre-line' }}>
                  {skills.map((v: any) => v.id).join(`\n\n`)}
                </div>
              }
              placement="right"
            >
              <InfoOutlinedIcon sx={{ ml: 1, verticalAlign: 'middle', fontSize: '1rem' }} />
            </Tooltip>
          </EllipsisText>
        </StyledAccordionSummary>
        <AccordionDetails sx={{ pt: 0 }}>
          {people.length === 0 && (
            <Fragment>
              <Divider sx={{ mb: '0.5rem' }} className="divider" />
              <Box sx={{ pl: 2 }}>
                <Typography variant="body4" sx={{ fontStyle: 'italic' }}>
                  No work items / endorsements were found, linking to this skill.
                </Typography>
              </Box>
            </Fragment>
          )}
          {people.length > 0 &&
            people.map((person: any, i: number) => {
              return (
                <Box key={person.id} ml="1rem">
                  <Divider
                    sx={{ mt: i === 0 ? '0' : '0.5rem', mb: '0.5rem' }}
                    className="divider"
                  />
                  <RecommendedPerson npeople={people.length} {...person} />
                </Box>
              );
            })}
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

const SkillGroupResult: React.FC<{
  nmatches?: number;
  id: string;
  name: string;
  people: any;
  expanded: boolean;
}> = ({ nmatches, id, name, people, expanded }) => {
  const { palette } = useTheme();
  return (
    <Card noElevation>
      <Accordion
        sx={{
          boxShadow: 'unset',
          width: 1,
          background: palette.Container.Bg,
          // Remove the top border: https://stackoverflow.com/a/76685872
          '&:before': { height: '0px' },
        }}
        // The content of Accordions is mounted by default even if the accordion is not expanded.
        // By enabling the unmountOnExit, we prevent the default behaviour.
        TransitionProps={{ unmountOnExit: true }}
        defaultExpanded={expanded}
      >
        <StyledAccordionSummary expandIcon={<NavigateNextIcon />} sx={{ py: 2 }}>
          <EllipsisText
            sx={{ fontSize: '1.5rem', fontWeight: 600 }}
            color={palette.Text.Headline}
            lineclamp={1}
          >
            {name}
          </EllipsisText>
        </StyledAccordionSummary>
        <AccordionDetails sx={{ pt: 0 }}>
          {people.length === 0 && (
            <Fragment>
              <Divider sx={{ mb: '0.5rem' }} className="divider" />
              <Box sx={{ pl: 2 }}>
                <Typography variant="body4" sx={{ fontStyle: 'italic' }}>
                  No work items / endorsements were found, linking to this skill.
                </Typography>
              </Box>
            </Fragment>
          )}
          {people.length > 0 &&
            people.map((person: any, i: number) => {
              return (
                <Box key={person.id} ml="1rem">
                  <Divider
                    sx={{ mt: i === 0 ? '0' : '0.5rem', mb: '0.5rem' }}
                    className="divider"
                  />
                  <Accordion
                    sx={{
                      boxShadow: 'unset',
                      width: 1,
                      background: 'inherit',
                      // Without this, the last person link, when expanded, would shift down
                      // a little bit, due to some extra margine getting automatically added
                      '&.Mui-expanded': { mt: 0 },
                      // Remove the top border: https://stackoverflow.com/a/76685872
                      '&:before': { height: '0px' },
                    }}
                    // The content of Accordions is mounted by default even if the accordion is not expanded.
                    // By enabling the unmountOnExit, we prevent the default behaviour.
                    TransitionProps={{ unmountOnExit: true }}
                    defaultExpanded={nmatches === 1 && people.length === 1}
                  >
                    <StyledAccordionSummary
                      expandIcon={<NavigateNextIcon />}
                      sx={{ pr: 0, pl: 1 }}
                    >
                      <PersonLinkContainer person={person} />
                    </StyledAccordionSummary>
                    <AccordionDetails sx={{ py: 0 }}>
                      <Box ml="1.5rem">
                        <PersonDetails profileId={person.id} skillId={id} />
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              );
            })}
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

const RecommendedPerson: React.FC<{
  npeople: number;
  id: string;
  fullName: string;
  skills: any;
}> = ({ npeople, skills, ...person }) => {
  const { palette } = useTheme();
  return (
    <Accordion
      sx={{
        boxShadow: 'unset',
        width: 1,
        background: 'inherit',
        // Without this, the last person link, when expanded, would shift down
        // a little bit, due to some extra margine getting automatically added
        '&.Mui-expanded': { mt: 0 },
        // Remove the top border: https://stackoverflow.com/a/76685872
        '&:before': { height: '0px' },
      }}
      // The content of Accordions is mounted by default even if the accordion is not expanded.
      // By enabling the unmountOnExit, we prevent the default behaviour.
      TransitionProps={{ unmountOnExit: true }}
      defaultExpanded={npeople === 1}
    >
      <StyledAccordionSummary expandIcon={<NavigateNextIcon />} sx={{ pr: 0, pl: 1 }}>
        <PersonLinkContainer person={person} />
      </StyledAccordionSummary>
      <AccordionDetails sx={{ py: 0, ml: 1 }}>
        {skills.map((skill: any) => {
          return (
            <Fragment key={skill.id}>
              <Divider sx={{ mt: '0.5rem', mb: '0.5rem' }} className="divider" />
              <Accordion
                sx={{
                  boxShadow: 'unset',
                  width: 1,
                  background: 'inherit',
                  // Without this, the last person link, when expanded, would shift down
                  // a little bit, due to some extra margine getting automatically added
                  '&.Mui-expanded': { mt: 0 },
                  // Remove the top border: https://stackoverflow.com/a/76685872
                  '&:before': { height: '0px' },
                }}
                // The content of Accordions is mounted by default even if the accordion is not expanded.
                // By enabling the unmountOnExit, we prevent the default behaviour.
                TransitionProps={{ unmountOnExit: true }}
                defaultExpanded={skills.length === 1}
              >
                <StyledAccordionSummary
                  expandIcon={<NavigateNextIcon />}
                  sx={{ pr: 0, pl: 1, py: 1 }}
                >
                  <Typography sx={{ fontSize: '0.875rem' }}>{skill.name}</Typography>
                </StyledAccordionSummary>
                <AccordionDetails sx={{ py: 0 }}>
                  <Box ml="1.5rem">
                    <PersonDetails profileId={person.id} skillId={skill.id} />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Fragment>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

const useMatchingEngineResult = (id: string, disabled?: boolean) => {
  const { account: user, hasOrganization } = useSession();

  const getRecords = () => {
    const queryParams = {
      id,
    };
    return matchingEngineApi.get('search', { params: queryParams });
  };

  const queryKey = ['matching-engine', user?.organizationId, 'get', id];

  const queryResponse = useQuery(queryKey, getRecords, {
    enabled: hasOrganization && !disabled,
  });

  return {
    ...queryResponse,
    data: queryResponse.data?.data,
  };
};

const StepGroupResult: React.FC<{
  nmatches?: number;
  depth: number;
  id: string;
  algoId: string;
  hasChildren: boolean;
  people: any;
}> = ({ nmatches, depth = 0, id, algoId, hasChildren, people }) => {
  const { palette } = useTheme();
  const [shouldRefetch, setShouldRefetch] = useState(nmatches === 1 && people.length === 0);
  const { data, isLoading } = useMatchingEngineResult(id, !shouldRefetch);
  const titleFontSize = depth === 0 ? '1.5rem' : depth === 1 ? '1.25rem' : '1rem';

  return (
    <Card noElevation>
      <Accordion
        sx={{
          boxShadow: 'unset',
          width: 1,
          background: palette.Container.Bg,
          // Remove the top border: https://stackoverflow.com/a/76685872
          '&:before': { height: '0px' },
        }}
        // The content of Accordions is mounted by default even if the accordion is not expanded.
        // By enabling the unmountOnExit, we prevent the default behaviour.
        TransitionProps={{ unmountOnExit: true }}
        defaultExpanded={depth === 0}
      >
        <StyledAccordionSummary expandIcon={<NavigateNextIcon />} sx={{ py: 2 }}>
          <EllipsisText
            sx={{ fontSize: titleFontSize, fontWeight: 600 }}
            color={palette.Text.Headline}
            lineclamp={1}
          >
            {id}
            {depth === 0 && (
              <Tooltip title={`Part of the ${algoId} ago`} placement="right">
                <InfoOutlinedIcon sx={{ ml: 1, verticalAlign: 'middle', fontSize: '1rem' }} />
              </Tooltip>
            )}
          </EllipsisText>
        </StyledAccordionSummary>
        <AccordionDetails sx={{ pt: 0, ml: 1 }}>
          <Accordion
            sx={{
              boxShadow: 'unset',
              width: 1,
              background: 'inherit',
              '&.Mui-expanded': { mt: 0 },
              // Remove the top border: https://stackoverflow.com/a/76685872
              '&:before': { height: '0px' },
            }}
            // The content of Accordions is mounted by default even if the accordion is not expanded.
            // By enabling the unmountOnExit, we prevent the default behaviour.
            TransitionProps={{ unmountOnExit: true }}
            defaultExpanded={true}
            onChange={(_, expanded) => {
              if (expanded && !shouldRefetch) setShouldRefetch(true);
            }}
          >
            <StyledAccordionSummary expandIcon={<NavigateNextIcon />} sx={{ pl: 1, pb: 1.5 }}>
              <Typography sx={{ pl: 1, fontSize: '1rem' }}>People with experience</Typography>
            </StyledAccordionSummary>
            <AccordionDetails sx={{ py: 0 }}>
              {people.length === 0 && (
                <Fragment>
                  <Divider sx={{ mb: '0.5rem' }} className="divider" />
                  <Box sx={{ pl: 2 }}>
                    <Typography variant="body4" sx={{ fontStyle: 'italic' }}>
                      No work items were found, linking to this step.
                    </Typography>
                  </Box>
                </Fragment>
              )}
              {people.length > 0 &&
                people.map((person: any, i: number) => {
                  return (
                    <Fragment key={person.id}>
                      <Divider
                        sx={{ mt: i === 0 ? '0' : '0.5rem', mb: '0.5rem' }}
                        className="divider"
                      />
                      <Accordion
                        sx={{
                          boxShadow: 'unset',
                          width: 1,
                          background: 'inherit',
                          // Without this, the last person link, when expanded, would shift down
                          // a little bit, due to some extra margine getting automatically added
                          '&.Mui-expanded': { mt: 0 },
                          // Remove the top border: https://stackoverflow.com/a/76685872
                          '&:before': { height: '0px' },
                        }}
                        // The content of Accordions is mounted by default even if the accordion is not expanded.
                        // By enabling the unmountOnExit, we prevent the default behaviour.
                        TransitionProps={{ unmountOnExit: true }}
                        defaultExpanded={
                          nmatches === 1 && people.length === 1 && !hasChildren
                        }
                      >
                        <StyledAccordionSummary
                          expandIcon={<NavigateNextIcon />}
                          sx={{ pr: 0, pl: 1 }}
                        >
                          <PersonLinkContainer person={person} />
                        </StyledAccordionSummary>
                        <AccordionDetails sx={{ py: 0 }}>
                          <Box ml="1.5rem">
                            <PersonDetails profileId={person.id} stepId={id} />
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Fragment>
                  );
                })}
            </AccordionDetails>
          </Accordion>
          <Fragment key="peopleWithSkills">
            <Divider sx={{ mt: '0.5rem', mb: '0.5rem' }} className="divider" />
            <Accordion
              sx={{
                boxShadow: 'unset',
                width: 1,
                background: 'inherit',
                '&.Mui-expanded': { mt: 0 },
                // Remove the top border: https://stackoverflow.com/a/76685872
                '&:before': { height: '0px' },
              }}
              // The content of Accordions is mounted by default even if the accordion is not expanded.
              // By enabling the unmountOnExit, we prevent the default behaviour.
              TransitionProps={{ unmountOnExit: true }}
              defaultExpanded={nmatches === 1 && people.length === 0}
              onChange={(_, expanded) => {
                if (expanded && !shouldRefetch) setShouldRefetch(true);
              }}
            >
              <StyledAccordionSummary
                expandIcon={<NavigateNextIcon />}
                sx={{ pl: 1, py: 1.5 }}
              >
                <Typography sx={{ pl: 1, fontSize: '1rem' }}>
                  People with matching skills
                </Typography>
              </StyledAccordionSummary>
              <AccordionDetails sx={{ py: 0 }}>
                {data?.recommendedPeople?.length === 0 && (
                  <Fragment>
                    <Divider sx={{ mb: '0.5rem' }} className="divider" />
                    <Box sx={{ pl: 2 }}>
                      <Typography variant="body4" sx={{ fontStyle: 'italic' }}>
                        Nobody else was found, with overlapping skills.
                      </Typography>
                    </Box>
                  </Fragment>
                )}
                {data?.recommendedPeople?.map((person: any, i: number) => {
                  return (
                    <Fragment key={person.id}>
                      <Divider
                        sx={{ mt: i === 0 ? '0' : '0.5rem', mb: '0.5rem' }}
                        className="divider"
                      />
                      <RecommendedPerson
                        npeople={data.recommendedPeople.length}
                        {...person}
                      />
                    </Fragment>
                  );
                }) ?? (
                  <Skeleton
                    variant="rectangular"
                    sx={{ borderRadius: '0.75rem' }}
                    height="10rem"
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </Fragment>
          {hasChildren && (
            <Fragment key="children">
              <Divider sx={{ mt: '0.5rem', mb: '0.5rem' }} className="divider" />
              <Accordion
                sx={{
                  boxShadow: 'unset',
                  width: 1,
                  background: 'inherit',
                  '&.Mui-expanded': { mt: 0 },
                  // Remove the top border: https://stackoverflow.com/a/76685872
                  '&:before': { height: '0px' },
                }}
                // The content of Accordions is mounted by default even if the accordion is not expanded.
                // By enabling the unmountOnExit, we prevent the default behaviour.
                TransitionProps={{ unmountOnExit: true }}
                defaultExpanded={false}
                onChange={(_, expanded) => {
                  if (expanded && !shouldRefetch) setShouldRefetch(true);
                }}
              >
                <StyledAccordionSummary
                  expandIcon={<NavigateNextIcon />}
                  sx={{ pl: 1, py: 1.5 }}
                >
                  <Typography sx={{ pl: 1, fontSize: '1rem' }}>Sub-activities</Typography>
                </StyledAccordionSummary>
                <AccordionDetails sx={{ py: 0 }}>
                  <Box>
                    {data?.children?.map((child: any, i: number) => (
                      <Fragment key={child.id}>
                        <Divider sx={{ mt: '0.5rem', mb: '0.5rem' }} className="divider" />
                        <StepGroupResult depth={depth + 1} {...child} />
                      </Fragment>
                    )) ?? (
                      <Skeleton
                        variant="rectangular"
                        sx={{ borderRadius: '0.75rem' }}
                        height="24rem"
                      />
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Fragment>
          )}
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

const AlgoGroupResult: React.FC<{
  id: string;
  children: any;
}> = ({ id, children }) => {
  const { palette } = useTheme();
  return (
    <Card noElevation>
      <Accordion
        sx={{
          boxShadow: 'unset',
          width: 1,
          background: palette.Container.Bg,
          // Remove the top border: https://stackoverflow.com/a/76685872
          '&:before': { height: '0px' },
        }}
        // The content of Accordions is mounted by default even if the accordion is not expanded.
        // By enabling the unmountOnExit, we prevent the default behaviour.
        TransitionProps={{ unmountOnExit: true }}
        defaultExpanded={true}
      >
        <StyledAccordionSummary expandIcon={<NavigateNextIcon />} sx={{ py: 2 }}>
          <EllipsisText
            sx={{ fontSize: '1.5rem', fontWeight: 600 }}
            color={palette.Text.Headline}
            lineclamp={1}
          >
            {id}
          </EllipsisText>
        </StyledAccordionSummary>
        <AccordionDetails sx={{ py: 0 }}>
          {children.map((step: any, i: number) => {
            return (
              <Fragment key={step.id}>
                <Divider
                  sx={{ mt: i === 0 ? '0' : '0.5rem', mb: '0.5rem' }}
                  className="divider"
                />
                <StepGroupResult depth={1} algoId={id} {...step} />
              </Fragment>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

const ResultsContainer: React.FC<{ data: any[] }> = ({ data: matches }) => {
  if (!matches.length) {
    return <NoResults />;
  }
  return (
    <>
      {matches.map((match) => {
        switch (match.type) {
          case 'MULTI_SKILL':
            return (
              <MultiSkillGroupResult key={match.id} {...match} nmatches={matches.length} />
            );
          case 'SKILL':
            return <SkillGroupResult key={match.id} {...match} nmatches={matches.length} />;
          case 'ALGO':
            return <AlgoGroupResult key={match.id} {...match} />;
          default:
            return <PreCard key={match.id} {...match} />;
        }
      })}
    </>
  );
};

const MatchingEngineSearchView: React.FC = () => {
  return (
    <InfiniteScrollContainer
      key={ExploreTabEnum.MATCHING_ENGINE}
      infiniteApiHook={useMatchingEngineResults}
      InfiniteListContainer={ResultsContainer}
      LoaderComponent={SearchSkeletonView}
      showFooter={false}
    />
  );
};
export default MatchingEngineSearchView;
