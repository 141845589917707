import { StyledSearchOption } from 'common/components/navigation/styles';
import { ListItemText } from 'common/components/material/ListItemButton';
import StaticDataSearchOptionItem from 'features/static-data/components/static-data-search-option-item';
import { IStaticDataSearchSuggestion } from 'features/static-data/interfaces/static-data-search-suggestion.interface';
import { useTheme } from '@mui/material';

export const StaticDataSearchOption: React.FC<{
  staticData: IStaticDataSearchSuggestion;
  searchTerm?: string;
  active?: boolean;
  onSelect?: (
    staticData: IStaticDataSearchSuggestion,
    ctx?: { navigationChangePending?: boolean },
  ) => void;
}> = ({ staticData, searchTerm, active, onSelect }) => {
  const { palette } = useTheme();

  return (
    <StyledSearchOption
      key={staticData.id}
      onClick={() => onSelect && onSelect(staticData)}
      dense={true}
      sx={{
        paddingY: '1px',
        backgroundColor: active ? palette.DropdownList.Hover : undefined,
      }}
    >
      <ListItemText>
        <StaticDataSearchOptionItem
          staticData={staticData}
          searchTerm={searchTerm}
          onVisitPageClick={() =>
            onSelect && onSelect(staticData, { navigationChangePending: true })
          }
        />
      </ListItemText>
    </StyledSearchOption>
  );
};
