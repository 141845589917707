import { styled, useTheme } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import AppBoxShadows from 'common/theme/AppBoxShadows';
import {
  Divider,
  Typography,
  CircularProgress,
  Stack,
  List,
} from 'common/components/material';
import PeopleSuggestionsList from './people-suggestion-list';
import StaticDataSuggestionsList from './static-data-suggestions-list';
import { useView } from 'common/hooks';
import { VerticalScrollableContainer } from '../container';
import { SearchSuggestionsProps, useFetchSearchSuggestions } from 'features/search';
import { EllipsisText } from '../ellipsis-text/ellipsis-text.component';
import { RecentSearchList } from 'features/search/components/recent-search-list.component';
import { IStaticDataSearchSuggestion } from 'features/static-data/interfaces/static-data-search-suggestion.interface';
import { SearchSuggestionType } from 'features/search/enums/search-suggestion-type.enum';
import { IProfileSearchSuggestion } from 'features/profiles/interfaces/profile-search-suggestion.interface';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetRecentSearches } from 'features/search/hooks/useGetRecentSearches';
import { StyledSearchOption } from './styles';
import { ListItemText } from '../material/ListItemButton';
import { ProfileSearchOption } from 'features/search/components/profile-search-option.component';
import { StaticDataSearchOption } from 'features/search/components/static-data-search-option.component';
import { ISearchSuggestion } from 'features/search/interfaces/search-suggestion.interface';
import { RecentSearchOption } from 'features/search/components/recent-search-option.component';
import {
  MatchingEngineSearchOption,
  MatchingEngineSuggestionList,
} from 'features/explore/components/MatchingEngineSearchView';

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  width: '100%',
  background: theme.palette.DropdownList.Bg,
  boxShadow: AppBoxShadows.shadow9,
  borderRadius: '0.375rem 0.375rem',
  zIndex: 100,
  color: theme.palette.DropdownList.Label,
  left: 0,
}));

const SearchSuggestions: React.FC<SearchSuggestionsProps> = ({
  searchTerm,
  topOffset = 0,
  excludeIds = [],
  onSelect,
}) => {
  const { palette } = useTheme();
  const { isDesktopView } = useView();
  const hasTextOrFilters = !!excludeIds.length || !!searchTerm;
  const isRecentSearchesOpen = !hasTextOrFilters;

  const {
    matchingEngine,
    people,
    staticData,
    isLoading: isLoadingSearchSuggestion,
  } = useFetchSearchSuggestions(searchTerm);
  const { isLoading: isLoadingRecentSearches, ...suggestionsData } = useGetRecentSearches({
    enabled: isRecentSearchesOpen,
  });

  const recentSearches = useMemo(
    () => (hasTextOrFilters ? [] : suggestionsData.recentSearches),
    [suggestionsData, hasTextOrFilters],
  );

  const isLoading = isLoadingSearchSuggestion || isLoadingRecentSearches;

  const getSuggestedStaticData = () => {
    if (!searchTerm) {
      return [];
    }

    const suggestedStaticData = staticData || [];
    return excludeIds.length
      ? suggestedStaticData.filter(
          (staticDataItem) => !excludeIds.includes(staticDataItem.id),
        )
      : suggestedStaticData;
  };

  const suggestedStaticData = getSuggestedStaticData();

  const hasMatchingEngineSuggestion = matchingEngine.length > 0;
  const hasProfileSuggestion = people.length > 0;
  const hasStaticDataSuggestion = suggestedStaticData.length > 0;

  const onSelectSuggestion = useCallback(
    (
      type: SearchSuggestionType,
      data: IProfileSearchSuggestion | IStaticDataSearchSuggestion | string,
      ctx: {
        navigationChangePending?: boolean;
        origin?: 'matching-engine-section' | 'profile-section';
      } = {},
    ) => {
      const selectedSuggestion = {
        id:
          type === SearchSuggestionType.TEXT
            ? (data as string)
            : (data as IProfileSearchSuggestion).id,
        type: type,
        data: data,
      };

      onSelect && onSelect(selectedSuggestion, ctx);
    },
    [onSelect],
  );

  const displayedText = searchTerm ? (
    <>
      <Stack direction="row" padding="10px" whiteSpace="nowrap">
        <Typography variant="body4">Press enter to view results for "</Typography>
        <EllipsisText lineclamp={1} variant="body4" fontWeight={700} display="inline-block">
          {searchTerm}
        </EllipsisText>
        <Typography variant="body4">"</Typography>
      </Stack>
      <Divider />
    </>
  ) : hasTextOrFilters || isEmpty(recentSearches) ? (
    <></>
  ) : (
    <>
      <Typography width="100%" variant="body4" padding="13px">
        Recent searches
      </Typography>
      <Divider />
    </>
  );

  const [indexOfActiveItem, setIndexOfActiveItem] = useState(-1);
  const activableItems = [
    ...recentSearches,
    ...matchingEngine,
    ...people,
    ...suggestedStaticData,
  ];

  useEffect(() => {
    const onKeyPress = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowDown':
          setIndexOfActiveItem(Math.min(indexOfActiveItem + 1, activableItems.length - 1));
          break;
        case 'ArrowUp':
          setIndexOfActiveItem(Math.max(indexOfActiveItem - 1, -1));
          break;
        case 'Enter':
          if (indexOfActiveItem < 0)
            onSelectSuggestion(SearchSuggestionType.TEXT, searchTerm);
          else if (indexOfActiveItem < recentSearches.length) {
            onSelect && onSelect(activableItems[indexOfActiveItem] as ISearchSuggestion);
          } else if (indexOfActiveItem < recentSearches.length + matchingEngine.length) {
            onSelectSuggestion(
              SearchSuggestionType.MATCHING_ENGINE,
              activableItems[indexOfActiveItem],
            );
          } else if (
            indexOfActiveItem <
            recentSearches.length + matchingEngine.length + people.length
          ) {
            onSelectSuggestion(
              SearchSuggestionType.PROFILE,
              activableItems[indexOfActiveItem] as IProfileSearchSuggestion,
            );
          } else if (
            indexOfActiveItem <
            recentSearches.length +
              matchingEngine.length +
              people.length +
              suggestedStaticData.length
          ) {
            onSelectSuggestion(
              SearchSuggestionType.STATIC_DATA,
              activableItems[indexOfActiveItem] as IStaticDataSearchSuggestion,
            );
          }
      }
    };
    window.addEventListener('keydown', onKeyPress);
    return () => window.removeEventListener('keydown', onKeyPress);
  }, [
    activableItems,
    indexOfActiveItem,
    onSelect,
    onSelectSuggestion,
    recentSearches.length,
    matchingEngine.length,
    people.length,
    suggestedStaticData.length,
  ]);
  return (
    <StyledContainer
      sx={{ top: isDesktopView ? topOffset : 60 }}
      data-testid="search-suggestions"
    >
      {displayedText}

      <VerticalScrollableContainer
        sx={
          isDesktopView
            ? undefined
            : {
                minHeight: '95vh',
                padding: '0',
                backgroundColor: palette.DropdownList.Bg,
                boxShadow: AppBoxShadows.shadow3,
              }
        }
        maxHeight={isDesktopView ? '80vh' : '100vh'}
        disableGutters
      >
        {isLoading && (
          <List>
            <StyledSearchOption key="no-result" dense={true} sx={{ paddingY: '1px' }}>
              <ListItemText>
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              </ListItemText>
            </StyledSearchOption>
          </List>
        )}
        {!isLoading && isRecentSearchesOpen && (
          <RecentSearchList showNoResults={!recentSearches.length}>
            {recentSearches.map((recentSearch) => (
              <RecentSearchOption
                key={recentSearch.id}
                recentSearch={recentSearch}
                active={recentSearch === activableItems[indexOfActiveItem]}
                onClick={onSelect}
              />
            ))}
          </RecentSearchList>
        )}
        {!isLoading && !isRecentSearchesOpen && (
          <>
            {hasMatchingEngineSuggestion && (
              <MatchingEngineSuggestionList
                onViewAllClick={() =>
                  onSelect &&
                  onSelect(
                    {
                      id: searchTerm,
                      type: SearchSuggestionType.TEXT,
                      data: searchTerm,
                    },
                    { origin: 'matching-engine-section' },
                  )
                }
              >
                {matchingEngine.map((item) => (
                  <MatchingEngineSearchOption
                    key={item.id}
                    algo={item}
                    searchTerm={searchTerm}
                    active={item === activableItems[indexOfActiveItem]}
                    onClick={(algo) =>
                      onSelectSuggestion(SearchSuggestionType.MATCHING_ENGINE, algo)
                    }
                  />
                ))}
              </MatchingEngineSuggestionList>
            )}
            {hasProfileSuggestion && hasMatchingEngineSuggestion && <Divider />}
            {hasProfileSuggestion && (
              <PeopleSuggestionsList
                onViewAllClick={() =>
                  onSelect &&
                  onSelect(
                    {
                      id: searchTerm,
                      type: SearchSuggestionType.TEXT,
                      data: searchTerm,
                    },
                    { origin: 'profile-section' },
                  )
                }
              >
                {people.map((profile) => (
                  <ProfileSearchOption
                    key={profile.id}
                    profile={profile}
                    searchTerm={searchTerm}
                    active={profile === activableItems[indexOfActiveItem]}
                    onClick={(profile) =>
                      onSelectSuggestion(SearchSuggestionType.PROFILE, profile)
                    }
                  />
                ))}
              </PeopleSuggestionsList>
            )}
            {hasStaticDataSuggestion &&
              (hasProfileSuggestion || hasMatchingEngineSuggestion) && <Divider />}
            {hasStaticDataSuggestion && (
              <StaticDataSuggestionsList>
                {staticData.map((staticData) => (
                  <StaticDataSearchOption
                    key={staticData.id}
                    staticData={staticData}
                    onSelect={(staticData, navigationChangePending) =>
                      onSelectSuggestion(
                        SearchSuggestionType.STATIC_DATA,
                        staticData,
                        navigationChangePending,
                      )
                    }
                    searchTerm={searchTerm}
                    active={staticData === activableItems[indexOfActiveItem]}
                  />
                ))}
              </StaticDataSuggestionsList>
            )}
          </>
        )}
      </VerticalScrollableContainer>
    </StyledContainer>
  );
};

export default SearchSuggestions;
