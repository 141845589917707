export enum ExploreTabEnum {
  MATCHING_ENGINE = 'matching-engine',
  PEOPLE = 'people',
  OPPORTUNITIES = 'opportunities',
  ALUMNI = 'alumni',
  BEHAVIOR = 'behaviors',
  PROJECTS = 'projects',
  PRAISE = 'praise',
  REVIEWS = 'reviews',
  COMPANY_UPDATES = 'company-updates',
  ORGANIZATION_EVENTS = 'events',
  VIDEOS = 'videos',
  // QUICK_LINKS = 'quick-links',
  WIN_LIST = 'win-lists',
}
