import { useDebounce, useQuery, useSession } from 'common/hooks';
import { validateMinLength } from 'common/utils/app.utils';
import { IProfileSearchSuggestion } from 'features/profiles/interfaces/profile-search-suggestion.interface';
import { IStaticDataSearchSuggestion } from 'features/static-data/interfaces/static-data-search-suggestion.interface';
import { fetchSearchSuggestionsApi } from '../search.service';
import { useFeatureAccess } from 'features/feature-access/hooks/useFeatureAccess';
import { FeaturesEnum } from 'features/feature-access/features.enum';

export const useFetchSearchSuggestions = (searchTerm: string) => {
  const { account: user } = useSession();
  const { canRead } = useFeatureAccess(FeaturesEnum.SEARCH_SUGGESTION);

  const debouncedTerm = useDebounce(searchTerm);

  const { data, isLoading, ...query } = useQuery(
    [user?.organizationId, 'search-suggestions', debouncedTerm],
    () => fetchSearchSuggestionsApi(debouncedTerm),
    {
      enabled: canRead && validateMinLength(debouncedTerm, 1),
    },
  );

  const { matchingEngine, people, staticData } = data?.data || {};

  return {
    ...query,
    isLoading,
    matchingEngine: (matchingEngine ?? []) as any[],
    people: (people ?? []) as IProfileSearchSuggestion[],
    staticData: (staticData ?? []) as IStaticDataSearchSuggestion[],
  };
};
