import { styled } from '@mui/material/styles';
import { AccordionSummary } from 'common/components/material/Accordion';

// Deprecated -- use StyledAccordionSummary instead
export const ProjectAccordionSummary = styled(AccordionSummary)({
  flexDirection: 'row-reverse',
  minHeight: 'auto',
  '&.Mui-expanded': {
    minHeight: 'auto',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
});
