export enum ExploreRoutesEnum {
  MATCHING_ENGINE = 'matching-engine',
  PEOPLE = 'people/*',
  OPPORTUNITIES = 'opportunities',
  ALUMNI = 'alumni',
  BEHAVIORS = 'behaviors',
  PROJECTS = 'projects',
  PRAISE = 'praise',
  REVIEWS = 'reviews',
  COMPANY_UPDATES = 'company-updates',
  // Route key reflects internal naming conventions, while the path reflects the name users see
  ORGANIZATION_EVENTS = 'events/*',
  VIDEOS = 'videos',
  QUICK_LINKS = 'quick-links',
  WIN_LIST = 'win-lists',
}

export enum PeopleSearchRoutesEnum {
  CARD = 'card',
  TABLE = 'table',
}
